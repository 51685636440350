<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex align-self-center>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Query</span>
          <v-spacer></v-spacer>
          <v-flex xl3 lg3 md3 pb-2 sm3 xs12 px-2 pl-sm-5>
            <v-dialog
              :retain-focus="false"
              v-model="dialog"
              persistent
              max-width="40%"
              :key="dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="font-family: kumbhBold"
                  dark
                  block
                  class="rounded-xl text-none"
                  color="#005f32"
                  v-bind="attrs"
                  v-on="on"
                >
                  Write a query
                </v-btn>
              </template>
              <v-card>
                <v-form v-model="addcat" ref="addcat">
                  <v-layout wrap>
                    <v-flex
                      xs12
                      pa-4
                      text-left
                      style="background: #005f32 !important"
                    >
                      <span
                        class="kumbhBold"
                        style="color: #ffffff; font-size: 20px"
                        >Write a query</span
                      >
                    </v-flex>
                    <v-flex xs12 align-self-center text-left pa-5 pt-8>
                      <v-textarea
                        v-model="message"
                        label="Enter message"
                        :rules="[rules.required]"
                        required
                        autofocus
                        outlined
                        dense
                      ></v-textarea>
                    </v-flex>
                  </v-layout>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      color="error"
                      tile
                      outlined
                      @click="dialogclose(), (name = null)"
                    >
                      Close
                    </v-btn>
                    &nbsp;
                    <v-btn
                      color="#005f32"
                      dark
                      tile
                      v-if="addcat"
                      @click="sendQuery()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-flex>
          <v-flex xs12 align-self-center>
            <v-spacer></v-spacer>
          </v-flex>

          <v-flex xs12 align-self-center py-2>
            <v-layout wrap justify-end>
              <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fromDate"
                      label="From Date"
                      outlined
                      class="rounded-xl"
                      hide-details
                      dense
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromDate"
                    no-title
                    @input="menu11 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="toDate"
                      label="To Date"
                      outlined
                      hide-details
                      dense
                      class="rounded-xl"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toDate"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <!-- <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-select
                  v-model="assetId"
                  :items="assetList"
                  outlined
                  item-text="name"
                  item-value="_id"
                  dense
                  hide-details
                  class="rounded-xl"
                  label="Assets"
                  clearable
                >
                </v-select>
              </v-flex> -->

              <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-select
                  v-model="FstatusType"
                  :items="statusTypes"
                  outlined
                  dense
                  hide-details
                  clearable
                  class="rounded-xl"
                  label="Status"
                >
                </v-select>
              </v-flex> 
            </v-layout>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-center v-if="queryList.length > 0">
          <v-flex xs12 align-self-center>
            <v-expansion-panels accordion>
              <v-expansion-panel v-for="(item, i) in queryList" :key="i">
                <v-expansion-panel-header>
                  <v-layout wrap class="kumbhMedium">
                    <v-flex xs8 lg10 align-self-center text-left pa-1>
                      <span> {{ item.query.slice(0, 100) }} </span>
                      <br />
                      <br />
                      <i>
                        <span>
                          {{ formatDate(item.create_date) }}
                        </span>
                      </i>
                    </v-flex>

                    <v-flex xs4 lg2 align-self-center text-right pa-1>
                      <span style="color:#005f32;" class="kumbhBold"> {{ item.status }}</span>
                    </v-flex>
                  </v-layout>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-layout wrap pa-2 class="kumbhRegular">
                    <v-flex xs12 align-self-center text-left pa-1>
                      <span style="color: #005f32"> Query </span>
                      <br />
                      <span>
                        {{ item.query }}
                      </span>
                    </v-flex>
                    <v-flex
                      xs12
                      align-self-center
                      text-left
                      pa-1
                      pt-3
                      v-if="item.status == 'Replied'"
                    >
                      <span style="color: #005f32"> Reply </span>
                      <br />
                      <span>
                        {{ item.reply }}
                      </span>
                    </v-flex>
                    <v-flex
                      xs12
                      align-self-center
                      text-right
                      pa-1
                      py-3
                      class="kumbhMedium"
                      v-if="item.status == 'Replied'"
                    >
                      <i>
                        <span style="color: #005f32">Replied by : </span>
                        <span v-if="item.repliedBy">
                          {{ item.repliedBy.name }}
                        </span>
                        <br />
                        <span style="color: #005f32">Replied on : </span>

                        <span v-if="item.replyTime">
                          {{ formatDate(item.replyTime) }}
                        </span>
                      </i>
                    </v-flex>
                    <v-flex
                      xs12
                      align-self-center
                      text-left
                      pa-1
                      pt-3
                      v-if="item.status != 'Replied'"
                    >
                      <span style="color: #005f32"> Reply </span>
                      <br />
                      <span> No reply! </span>
                    </v-flex>
                  </v-layout>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-flex>
        </v-layout>
        <v-layout wrap v-else>
          <v-flex xs12 align-self-center pa-16>
            <span class="kumbhRegular">No Queries found!</span>
          </v-flex>
        </v-layout>

        <v-dialog persistent v-model="editdialog" max-width="70%">
          <v-card tile>
            <v-layout wrap v-if="editingitem">
              <v-flex
                xs12
                pa-4
                text-left
                align-self-center
                style="background: #005f32 !important"
              >
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px"
                  >Ressign Asset</span
                >
              </v-flex>
              <v-container>
                <v-layout wrap pa-4>
                  <v-flex
                    xs12
                    sm6
                    pa-2
                    align-self-center
                    text-left
                    v-if="editingitem.assetId"
                  >
                    <v-text-field
                      v-model="editingitem.assetId.name"
                      label="Asset"
                      outlined
                      dense
                      readonly
                      hide-details
                    >
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 pa-2 align-self-center text-left>
                    <v-select
                      v-model="assignType"
                      :items="assignList"
                      :rules="[rules.required]"
                      hide-details
                      outlined
                      dense
                      label="Choose assign type"
                    >
                    </v-select>
                  </v-flex>

                  <v-flex
                    xs12
                    sm6
                    align-self-center
                    text-left
                    pa-2
                    v-if="
                      assignType == 'Department' || assignType == 'Employee'
                    "
                  >
                    <v-select
                      v-model="departmentId"
                      :items="deptList"
                      item-text="deptName"
                      item-value="_id"
                      :rules="[rules.required]"
                      hide-details
                      outlined
                      dense
                      @change="employeeId = null"
                      label="Choose department"
                    >
                    </v-select>
                  </v-flex>

                  <v-flex
                    xs12
                    sm6
                    align-self-center
                    text-left
                    pa-2
                    v-if="assignType == 'Employee'"
                  >
                    <v-autocomplete
                      v-model="employeeId"
                      :items="empList"
                      item-text="name"
                      :search-input.sync="search1"
                      item-value="_id"
                      :rules="[rules.required]"
                      required
                      outlined
                      hide-details
                      label="Choose employee"
                      dense
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="quantity"
                      label="Quantity"
                      :rules="[rules.required]"
                      required
                      hide-details
                      outlined
                      type="number"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-layout>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                tile
                outlined
                @click="(editdialog = false), getData(), clearOff()"
              >
                Close
              </v-btn>
              &nbsp;
              <v-btn
                v-if="!g"
                color="#005f32"
                dark
                tile
                @click="validateEdit(editingitem._id)"
              >
                <span v-if="departmentId != editingitem.departmentId">
                  Transfer
                </span>
                <span v-else> Reassign </span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#005f32"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      totalData: 0,
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      name: null,
      assetId: null,
      assetList: [],
      date: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
      assignTypes: ["Not Assigned", "Department", "Employee"],
      assignType: null,
      FassignType: null,
      assignList: ["Department", "Employee"],
      employeeId: null,
      departmentId: null,
      search1: null,

      empList: [],
      deptList: [],
      message: null,
      queryList: null,
      FstatusType:null,
      statusTypes: ['Unread', 'Read', 'Replied'],
    };
  },
  components: {
    // ImageComp,
  },
  watch: {
    fromDate() {
      if (this.currentPage > 1) this.currentPage = 1;

      this.getData();
    },
    toDate() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
    assetId() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
    FstatusType() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },

    departmentId() {
      this.getEmp();
    },
    search1() {
      this.getEmp();
    },
    
    currentPage() {
      this.getData()
    }
  },
  mounted() {
    this.getDept();
    this.getEmp();
    if (this.fromDate) {
      this.fromDate = null;
    }
    if (this.toDate) {
      this.toDate = null;
    }
    this.getData();
    this.getAssets();
  },

  methods: {
    sendQuery() {
      var data = {};
      data["query"] = this.message;
      axios({
        url: "/query/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.name = null;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getAssets() {
      this.appLoading = true;
      axios({
        url: "/assets/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.assetList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getDept() {
      this.appLoading = true;
      axios({
        url: "/department/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.deptList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getEmp() {
      var exp = "";
      if (this.editingitem.employeeId) {
        exp = this.editingitem.employeeId._id;
      }
      this.appLoading = true;
      axios({
        url: "/employees/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          departmentId: this.departmentId,
          keyword: this.search1,
          except: exp,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.empList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getData() {
      this.appLoading = true;
      axios({
        url: "/employee/own/queries/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.currentPage,
          limit: 20,
          from: this.fromDate,
          to: this.toDate,
          status: this.FstatusType,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.queryList = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    dismantleItem(cat) {
      if (this.prevQuantity < this.dismantleQuantity) {
        this.msg = "Quantity provided should be same or lesser than the stock!";
        this.showsnackbar = true;
        return;
      } else {
        var data = {};
        data["inventoryStockId"] = cat._id;
        data["dismantledQuantity"] = this.dismantleQuantity;
        axios({
          url: "/asset/inventoryStock/dismantle",
          method: "POST",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: data,
        })
          .then((response) => {
            this.delete = false;
            this.appLoading = false;
            if (response.data.status) {
              this.msg = "Asset dismantled sucessfully";
              this.showsnackbar = true;

              this.getData();
            } else {
              this.getData();
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },

    editcat(item) {
      this.editingitem = item;

      this.getEmp();
      if (item.departmentId)
        this.editingitem.departmentId = item.departmentId._id;
      // if (item.employeeId) this.employeeId = item.employeeId._id;
      this.editdialog = true;
    },
    validateEdit() {
      if (this.quantity > this.editingitem.quantity) {
        this.msg =
          "Quantity should be less than or same as already assigned quantity!";
        this.showsnackbar = true;
        return;
      } else if (!this.assignType) {
        this.msg = "Please provide assign type";
        this.showsnackbar = true;
        return;
      } else if (!this.departmentId) {
        this.msg = "Please choose a department";
        this.showsnackbar = true;
        return;
      } else if (this.assignType == "Employee" && !this.employeeId) {
        this.msg = "Please choose an employee";
        this.showsnackbar = true;
        return;
      } else {
        this.edit();
      }
    },
    edit() {
      var data = {};
      data["inventoryStockId"] = this.editingitem._id;
      data["assignType"] = this.assignType;
      data["departmentId"] = this.departmentId;
      data["employeeId"] = this.employeeId;
      data["quantity"] = this.quantity;
      axios({
        url: "/asset/reassign/transfer",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
            this.clearOff();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    clearOff() {
      this.employeeId = null;
      this.assignType = null;
      this.quantity = null;
      this.departmentId = null;
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      day = day < 10 ? "0" + day : day;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>
